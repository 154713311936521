import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Footer.css";
import footerLogo from "../Assist/footerLogo.png";
import { Link } from "react-router-dom";

function Footer() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const handleSubscribe = async () => {
    if (!email || !firstName) {
      alert("Please enter your first name and email address.");
      return;
    }

    try {
      const response = await axios.post(
        "https://ardra-a-ibackend.vercel.app/api/contact",

        {
          email,
          firstName,
        }
      );

      if (response.status === 200) {
        toast.success("Subscription successful");
        setEmail("");
        setFirstName("");
      } else {
        toast.error("Subscription failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <footer>
      <ToastContainer />
      <div className="footer-container">
        <div className="footer-column">
          <Link to="/">
            <img src={footerLogo} alt="Company Logo" height={130} width={150} />
          </Link>

          <p className="ms-0">Manage your energy with Ardra AI</p>
        </div>
        <div className="footer-column">
          <h5>Products</h5>
          <ul>
            <li>
              <Link to="/emsPage">EMS</Link>
            </li>
            <li>
              <Link to="/probe">PROBE</Link>
            </li>
            <li>
              <Link to="/energyGPTPage">ardraGPT</Link>
            </li>
            <li>
              <Link to="/document">Resources</Link>
            </li>
            <li>
              <Link to="/partner">Partners</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h5>Company</h5>
          <ul>
            <li>
              <Link to="/aboutus">About</Link>
            </li>
            <li>
              <Link to="/blog">Blogs</Link>
            </li>
            <li>
              <Link to="/career">Career</Link>
            </li>
            <li>
              <Link to="/aboutus#team">Team</Link>
            </li>
            <li>
              <Link to="/#faq">FAQs</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h5>Contact Us</h5>
          <div className="subscribe">
            <input
              type="text"
              placeholder="Enter your first name"
              aria-label="First name for subscription"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Enter your email"
              aria-label="Email for subscription"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <button type="button" onClick={handleSubscribe}>
              Subscribe
            </button>
          </div>
        </div>
        <div className="footer-column bottom">
          <h5>Follow Us</h5>
          <div className="social-media-p">
            <div className="social-media">
              <a
                href="https://www.facebook.com/ardracloud"
                aria-label="Facebook"
                target="_blank"
              >
                <i
                  className="fa-brands fa-facebook fa-xl"
                  style={{ color: "#ffffff" }}
                ></i>
              </a>
              <a
                href="https://twitter.com/ArdraAi"
                aria-label="Twitter"
                target="_blank"
              >
                <i
                  className="fa-brands fa-x-twitter fa-xl"
                  style={{ color: "#ffffff" }}
                ></i>
              </a>
              <a
                href="https://www.linkedin.com/company/88407851/admin/feed/posts/"
                aria-label="LinkedIn"
                target="_blank"
              >
                <i
                  className="fa-brands fa-linkedin-in fa-xl"
                  style={{ color: "#ffffff" }}
                ></i>
              </a>
              <a
                href="https://www.instagram.com/ardra.ai.india/"
                aria-label="Instagram"
                target="_blank"
              >
                <i
                  className="fa-brands fa-instagram fa-xl"
                  style={{ color: "#ffffff;" }}
                ></i>
              </a>
              <a
                href="https://www.youtube.com/@ardracloud"
                aria-label="youtube"
                target="_blank"
              >
                <i
                  className="fa-brands fa-youtube fa-xl"
                  style={{ color: "#ffffff;" }}
                ></i>
              </a>
            </div>
            <p style={{ textAlign: "center" }}>
              166 Geary St STE 1500 Suite #278, San Francisco, California 94108,
              United States
            </p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Ardra AI Inc | USA | India | All rights reserved |</p>
        <div className="footer-links">
          <span>
            {" "}
            <a href="/terms-and-conditions" className="footer-link">
              Terms & Conditions
            </a>
          </span>
          <span className="footer-separator"> |</span>
          <span>
            <a href="/privacy-policy" className="footer-link">
              Privacy Policy
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
