import blog1 from "../../Assist/Blogs/blog1.jpeg";
import ABeginnersGuidetoSmartGridsandTheirBenefitsmain from "../../Assist/Blogs/A-Beginners-Guide-to-Smart-Grids-and-Their-Benefits-main.jpg";
import SustainabilityGoalsmain from "../../Assist/Blogs/Sustainability-Goals-main.png";
import HowIoTandAIAreShapingtheFuturemain from "../../Assist/Blogs/How-IoT-and-AI-Are-Shaping-the-Future-main.png";
import UnderstandingEMSBenefitsandBestPracticesmain from "../../Assist/Blogs/Understanding-EMS-Benefits-and-Best-Practices-main.jpg";
import TheRoleofPredictiveAnalyticsinEnergyEfficiencymain from "../../Assist/Blogs/The-Role-of-Predictive-Analytics-in-Energy-Efficiency-main.jpg";
import LeveragingMachineLearningmain from "../../Assist/Blogs/Leveraging-Machine-Learning-main.jpg";
import TopTrendsinEnergyManagementmain from "../../Assist/Blogs/Top-Trends-in-Energy-Management-main.png";
import WaysArdraAICanHelpReduceEnergyCostsmain from "../../Assist/Blogs/Ways-Ardra-AI-Can-Help-Reduce-Energy-Costs-main.jpg";
import TransformingEnergyEfficiencymain from "../../Assist/Blogs/Transforming-Energy-Efficiency-main.jpg";
import TheFutureOfEnergyManagementmain from "../../Assist/Blogs/The-Future-Of-Energy-Management-main.jpg";
import StreamliningEnergyManagementforaUniversitymain from "../../Assist/Blogs/Streamlining-Energy-Management-for-a-University-main.jpg";

const BlogData = [
  {
    id: 1,
    category: "Article",
    type: "Informative Article",
    title:
      "The Future of Energy Management: How AI is Revolutionizing the Industry",
    summary:
      "Discusses the role of AI in optimizing energy consumption, reducing costs, and increasing efficiency in both industrial and commercial sectors.",
    link: "/ArticleDetail_blog/The-Future-of-Energy-Management/1",
    image: TheFutureOfEnergyManagementmain,
  },
  {
    id: 2,
    category: "Article",
    type: "Educational Article",
    title:
      "Understanding Energy Management Systems (EMS): Benefits and Best Practices",
    summary:
      "Provides an overview of EMS, their advantages, and best practices for implementation in various industries.",
    link: "/ArticleDetail_blog/Understanding-Energy-Management-Systems/2",
    image: UnderstandingEMSBenefitsandBestPracticesmain,
  },
  // {
  //   id: 3,
  //   category: "Case-study",
  //   title: "Case Study: How Ardra AI Improved Energy Efficiency for a Leading Manufacturer",
  //   summary:
  //     "Provides a real-world example of how Ardra AI solutions helped a client optimize energy use and reduce costs.",
  //   link: "/Case-studyDetail_blog/How-Ardra-AI-Improved-Energy-Efficiency/3",
  //   author: "Author C",
  //   date: "2024-09-03",
  //   image: blog1,
  // },
  {
    id: 4,
    category: "Article",
    type: "Technical Article",
    title: "The Role of Predictive Analytics in Energy Efficiency",
    summary:
      "Explains how predictive analytics can forecast energy needs, prevent equipment failure, and optimize energy use.",
    link: "/ArticleDetail_blog/The-Role-of-Predictive-Analytics/4",
    image: TheRoleofPredictiveAnalyticsinEnergyEfficiencymain,
  },
  {
    id: 5,
    category: "Article",
    type: "Guide Article",
    title: "A Beginner's Guide to Smart Grids and Their Benefits",
    summary:
      "Educates readers about smart grids, their integration with AI technologies, and how they contribute to sustainable energy management.",
    link: "/ArticleDetail_blog/A-Beginner's-Guide-to-Smart-Grids/5",
    image: ABeginnersGuidetoSmartGridsandTheirBenefitsmain,
  },
  {
    id: 6,
    category: "Product-spotlight",
    title: "5 Ways Ardra AI Can Help Reduce Energy Costs in Manufacturing",
    summary:
      "Focuses on practical ways Ardra AI solutions can reduce energy consumption and costs in the manufacturing sector.",
    link: "/Product-spotlightDetail_blog/Ways-Ardra-AI-Can-Help-Reduce-Energy-Costs/6",
    image: WaysArdraAICanHelpReduceEnergyCostsmain,
  },
  {
    id: 7,
    category: "Article",
    type: "Technical Article",
    title:
      "Leveraging Machine Learning for Real-Time Energy Monitoring and Management",
    summary:
      "Discusses how machine learning algorithms analyze energy usage patterns in real-time and provide actionable insights for energy optimization.",
    link: "/ArticleDetail_blog/Leveraging-Machine-Learning/7",
    image: LeveragingMachineLearningmain,
  },
  {
    id: 8,
    category: "Article",
    type: "Trend Article",
    title: "Top Trends in Energy Management for 2024",
    summary:
      "Highlights emerging trends like decentralized energy systems, renewable integration, and AI-driven energy storage.",
    link: "/ArticleDetail_blog/Top-Trends-in-Energy-Management/8",

    image: TopTrendsinEnergyManagementmain,
  },
  // {
  //   id: 9,
  //   category: "Product-spotlight",
  //   title: "Ardra ONE: A Revolutionary Approach to Energy Management with Edge Hardware",
  //   summary:
  //     "Details the features and advantages of Ardra ONE's edge hardware, including benefits like ARM controllers and LoRaWAN network integration.",
  //   link: "/Product-spotlightDetail_blog/Ardra-ONE-A-Revolutionary-Approach/9",
  //   image: blog1,
  // },
  {
    id: 10,
    category: "Article",
    type: "Informative Article",
    title: "How IoT and AI Are Shaping the Future of Energy Management",
    summary:
      "Explores the synergy between IoT and AI in creating more responsive and efficient energy management systems.",
    link: "/ArticleDetail_blog/How-IoT-and-AI-Are-Shaping-the-Future/10",
    // author: "Author H",
    // date: "2024-09-08",
    image: HowIoTandAIAreShapingtheFuturemain,
  },
  // {
  //   id: 11,
  //   category: "Case-study",
  //   title: "Case Study: How Ardra AI Enhanced Energy Efficiency for a Large-Scale Manufacturing Plant",
  //   summary:
  //     "This case study focuses on how Ardra AI's advanced energy management system helped a large-scale manufacturing plant optimize its energy consumption and significantly reduce operational costs.",
  //   link: "/Case-studyDetail_blog/Improving-Energy-Efficiency/11",
  //   author: "Author C",
  //   date: "2024-09-03",
  //   image: blog1,
  // },
  // {
  //   id: 12,
  //   category: "Article",
  //   type: "Informative Article",
  //   title: "Sustainability Goals: How Smart Energy Management Can Help Achieve Net Zero",
  //   summary:
  //     "Discusses the importance of smart energy management in achieving sustainability goals and how Ardra AI can contribute.",
  //   link: "/ArticleDetail_blog/Sustainability-Goals/12",
  //   author: "Author H",
  //   date: "2024-09-08",
  //   image: SustainabilityGoalsmain,
  // },
  // {
  //   id: 13,
  //   category: "Article",
  //   type: "Technical Article",
  //   title: "How does AI-driven predictive maintenance work in industrial settings",
  //   summary:
  //     "AI-driven predictive maintenance leverages IoT and machine learning to forecast equipment failures, optimizing maintenance schedules and reducing downtime. This proactive approach improves operational efficiency and extends equipment lifespan.",
  //   link: "/ArticleDetail_blog/How-does-AI-driven-predictive-maintenance-work/13",
  //   author: "Author H",
  //   date: "2024-09-08",
  //   image: blog1,
  // },
  // {
  //   id: 14,
  //   category: "Case-study",
  //   title: "Case Study: Optimizing Energy Use in a Smart Building Complex with Ardra AI",
  //   summary:
  //     "This case study highlights how Ardra AI’s solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs.",
  //   link: "/Case-studyDetail_blog/Optimizing-Energy-Use/14",
  //   author: "Author C",
  //   date: "2024-09-03",
  //   image: blog1,
  // },

  {
    id: 15,
    category: "Case-study",
    title:
      "Case Study: Transforming Energy Efficiency for an Indian Factory Owner",
    summary:
      "This case study highlights how Ardra AI’s solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs.",
    link: "/Case-studyDetail_blog/Transforming-Energy-Efficiency/15",
    image: TransformingEnergyEfficiencymain,
  },
  {
    id: 16,
    category: "Case-study",
    title:
      "Case Study: Streamlining Energy Management for a University with ArdraGPT",
    summary:
      "This case study highlights how Ardra AI’s solutions were implemented in a modern smart building complex to achieve optimal energy efficiency and reduce overall energy costs.",
    link: "/Case-studyDetail_blog/Streamlining-Energy-Management-for-a-University/16",
    image: StreamliningEnergyManagementforaUniversitymain,
  },
];
// export const BlogIDs = BlogData.map(blog => blog.id);

export default BlogData;
